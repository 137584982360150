@font-face {
  font-family: "Adelle";
  src: local("Adelle"), url("./fonts/AdelleRegular.ttf");
}

.App {
  text-align: center;
  font-family: "Adelle";
}

/** Button */
.button {
  color: white;
  width: 150px;
  display: inline-block;
  margin: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10%;
  text-align: center;
  min-height: 30px;
  position: relative;
}

.button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
.button:hover > div {
  background-color: rgba(0, 0, 0, 0.05);
}
.square {
  min-height: 150px;
}

.cta {
  width: 300px;
}

.circle {
  padding: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100% 100% 200% 10%;
  height: 55px;
  width: 55px;
}

.button-text {
  position: absolute;
  min-width: 150px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** Colours */

.orange {
  background-color: #eeb052;
}

.yellow {
  background-color: #d8ac2c;
}

.blue {
  background-color: #4690b8;
}

.green {
  background-color: #569c5e;
}

.pink {
  background-color: #e890ea;
}

.red {
  background-color: #f67878;
}

.violet {
  background-color: #bfc0e4;
}

.grey {
  background-color: #e6e6e6;
  color: #363a40;
}

/** Doc Profile */

.doc-profile {
  min-width: 300px;
  display: inline-block;
  margin: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.doc-profile:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.img-doc {
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doc-name {
  font-size: larger;
  margin-left: 5px;
}

.doc-bio {
  margin-top: 10px;

  padding-left: 5px;
  max-width: 100%;
  color: #939496;
}

.doc-circle {
  padding: 1px;
  background-color: rgba(255, 203, 51, 0.3);
  border-radius: 100% 10% 10% 10%;
  height: 40px;
  width: 40px;
  bottom: 0px;
  right: 0px;
  position: absolute;
}

/** History Q */

.history-question {
  font-size: x-large;
  padding: 10px;
}

/** Login Screen */

.login-image {
  height: 50vh;
}

.login-text {
  font-size: large;
  padding: 4px;
}

.login-title {
  font-size: xx-large;
  padding: 10px;
}

.login-logo {
  max-height: 150px;
}

/** Upload **/
.upload {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 300px;
  border-radius: 0.5em;
  margin: 10px;
  align-self: center;
}

.upload:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
